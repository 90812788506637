<template>
  <PflegeBaseComponentTemplate
    v-editable="props.blok"
    :actionsAlignment="props.blok.actionsAlignment"
    :backgroundColor="getPflegeColornameFromHex(props.blok.backgroundColor.value)"
    :data-uid="props.blok._uid"
    :heading="props.blok.heading"
    :headingTag="props.blok.headingTag"
    :textAlign="props.blok.textAlign"
  >
    <template v-if="willRender(props.blok.content)" #default>
      <RichtextResolver :blok="props.blok.content" />
    </template>

    <template v-if="props.blok.actions?.length > 0" #actions>
      <WPElementButton :blok="props.blok.actions[0]" />
    </template>
  </PflegeBaseComponentTemplate>
</template>

<script setup>
import { PflegeBaseComponentTemplate } from '@shared'

const props = defineProps({
  blok: {
    type: Object,
    required: true
  }
})

const { getPflegeColornameFromHex } = usePflegeUtils()
</script>
